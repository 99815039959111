import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './component/common/Header';
import Footer from './component/common/Footer';
import Home from './component/pages/Home';
import About from './component/pages/About';
//import Contact from './pages/Contact';
import './App.css';

function App() {
  return (
    <Router>
      <main>
        <div className="container-fluid App px-0">
          <header>
            <div className="container">
              <Header />
            </div>  
          </header>
          <div className="mainContent">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
          <footer className="khukriFooter">
            <div className="container">
              <Footer />
            </div>
          </footer>
        </div>
      </main>
    </Router>
  );
};

export default App;
