import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/images/khukri-title.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
    return(
        <div className="row justify-content-center align-items-center">
            <div className="col-lg-4">
                <figure className="footerLogo text-center">
                    <img src={footerLogo} alt="" class="w-50 img-responsive" />
                </figure>
            </div>
            <div className="col-lg-12">
                <div className="hr"></div>
            </div>
            <div className="col-md-6">
                <div className="footerLeftContent">
                    <h2>Talk To Us</h2>
                    <p><Link to="tel:+977-1-5450447">+977-1-5450447</Link></p>
                    <p class="mb-0"><Link to="mailto:info@nepaldistilleries.com.np">info@nepaldistilleries.com.np</Link> | <Link to="mailto:sales@nepaldistilleries.com.np">sales@nepaldistilleries.com.np</Link></p>
                </div>
            </div>
            <div className="col-md-6 justify-content-between">
                <ul className="nav footerSocial justify-content-end align-items-center list-unstyled d-flex">
                    <li className="ms-3">
                        <Link className="" to="https://facebook.com" target="_blank">
                            <FontAwesomeIcon icon={faFacebook} />
                        </Link>
                    </li>
                    <li className="ms-3">
                        <Link className="" to="https://facebook.com" target="_blank">
                            <FontAwesomeIcon icon={faTwitter} />
                        </Link>
                    </li>
                    <li className="ms-3">
                        <Link className="" to="https://facebook.com" target="_blank">
                            <FontAwesomeIcon icon={faInstagram} />
                        </Link>
                    </li>
                    <li className="ms-3">
                        <Link className="" to="https://facebook.com" target="_blank">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </Link>
                    </li>
                    <li className="ms-3">
                        <Link className="" to="https://facebook.com" target="_blank">
                            <FontAwesomeIcon icon={faYoutube} />
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="col-lg-12">
                <div className="hr"></div>
            </div>
            <div className="col-lg-12">
                <p className="text-center mb-0">© 2024. The Nepal Distilleries Private Limited. All Rights Reserved. Designed & Developed by <Link to="https://webdev24x7.com" target="_blank">WebDev24x7</Link>.</p>
            </div>
        </div>
    )
}
export default Footer;