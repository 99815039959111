import React from "react";
import { Helmet } from "react-helmet";

const About = () =>{
    return(
        <div className="container">
            <Helmet>
                <title>About Us page</title>
                <meta name="description" content="About us page"/>
            </Helmet>
            <div className="row">
                <div className="col-lg-12">
                    <h2>Hello About Us</h2>
                    <p>This is my desc</p>
                </div>
            </div>
        </div>
    );
}

export default About;