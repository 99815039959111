import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import homeBannerFirst from "../../assets/images/Smoked_Homepage.jpg";
import homeBannerSecond from "../../assets/images/Our-Craft_Banner.jpg";
import xxxRumLogo from "../../assets/images/XXX_Logo.png";
import xxxRumBottle from "../../assets/images/XXX_Rum.jpg";
import whiteRumLogo from "../../assets/images/White_Rum_Logo.png";
import whiteRumBottle from "../../assets/images/White_Rum.jpg";
import spicedRumLogo from "../../assets/images/Spiced_Logo.png";
import spicedRumBottle from "../../assets/images/Spiced_Homepage.jpg";
import coroRumLogo from "../../assets/images/Coronation_Logo.png";
import coroRumBottle from "../../assets/images/Coronation.jpg";
import caskRumLogo from "../../assets/images/Smoked_Logo.png";
import caskRumBottle from "../../assets/images/Smoked.jpg";
import virtualTour from "../../assets/images/virtual.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blackRum from "../../assets/images/bottle-black-rum.webp";
import redRum from "../../assets/images/bottle-red-rum.webp"; 

const Home = () =>{
    const heroSliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true
    }
    const productSliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        auroplay: true,
        responsive: [
            { breakpoint: 768, settings: { slidesToShow: 2 } },
            { breakpoint: 480, settings: { slidesToShow: 1 } }
        ]
    }
    return(
        <div className="container-fluid px-0">
            <Helmet>
                <title>Home - WebDev24x7</title>
                <meta name="description" content="Homepage for websdev 24x7" />
            </Helmet>
            
            <div className="homeFirst">
                <Slider {...heroSliderSettings} className="heroSlider">
                    <div className="singleSlide">
                        <img src={homeBannerFirst} alt="Homepage banner 1" className="w-100 img-responsive" />
                    </div>
                    <div className="singleSlide">
                        <img src={homeBannerSecond} alt="Homepage banner 2" className="w-100 img-responsive" />
                    </div>
                </Slider>
            </div>
            <div className="homeSecond">    
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-lg-8">
                            <div className="homeSecondContent">
                                <h2>Crafting Legacy <span>One Sip at a Time</span></h2>
                                <p>Khukri Rum, crafted in the Himalayas, carries the whispers of ancient traditions and the soul of a vibrant culture of Nepal. Each sip of Khukri is a voyage through time, celebrating the artistry and dedication that define our legacy.</p>
                                <Link to="/about" className="btn btn-primary">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="homeThird">
                <div class="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="homeThirdContent">
                                <h2>The Pinnacle <span>of Nepalese Craftmanship</span></h2>
                                <p>Khukri’s blends are crafted with passion and precision. Imagine the robust Khukri XXX Rum, echoing our rugged terrain; the Khukri White Rum, pure as the snow-capped peaks; or the Khukri Spiced Rum, infused with the essence of Nepalese spices. Each blend is a symphony of flavours, offering a taste of Nepal’s rich heritage.</p>
                                <Link to="/our-craft" className="btn btn-primary">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="homeFourth">
                <div class="container">
                    <div class="row">
                        <Slider {...productSliderSettings} className="productSlider">
                            <div>
                                <div className="productHomeSlide">
                                    <Link to="#">
                                        <figure className="productHomeSlideLogo">
                                            <img src={xxxRumLogo} alt="" className="w-100 img-responsive" />
                                        </figure>
                                        <div className="productHomeSlideContent">
                                            <img src={xxxRumBottle} alt="" className="w-100 img-responsive" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className="productHomeSlide">
                                    <Link to="#">
                                        <figure className="productHomeSlideLogo">
                                            <img src={whiteRumLogo} alt="" className="w-100 img-responsive" />
                                        </figure>
                                        <div className="productHomeSlideContent">
                                            <img src={whiteRumBottle} alt="" className="w-100 img-responsive" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className="productHomeSlide">
                                    <Link to="#">
                                        <figure className="productHomeSlideLogo">
                                            <img src={spicedRumLogo} alt="" className="w-100 img-responsive" />
                                        </figure>
                                        <div className="productHomeSlideContent">
                                            <img src={spicedRumBottle} alt="" className="w-100 img-responsive" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className="productHomeSlide">
                                    <Link to="#">
                                        <figure className="productHomeSlideLogo">
                                            <img src={coroRumLogo} alt="" className="w-100 img-responsive" />
                                        </figure>
                                        <div className="productHomeSlideContent">
                                            <img src={coroRumBottle} alt="" className="w-100 img-responsive" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className="productHomeSlide">
                                    <Link to="#">
                                        <figure className="productHomeSlideLogo">
                                            <img src={caskRumLogo} alt="" className="w-100 img-responsive" />
                                        </figure>
                                        <div className="productHomeSlideContent">
                                            <img src={caskRumBottle} alt="" className="w-100 img-responsive" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="row">
                        <div className="tourSectionCta">
                            <Link to="#">    
                                <img src={virtualTour} className="w-100 img-resposive" alt="Khukri Virtual Tour"/>
                            </Link>
                            <Link to="/contact">Schedult a Visit to Distillery</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="homeFifth">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="homeFifthContent">
                                <h2>The Essence of Nepal in Every Bottle</h2>
                                <p>The journey of Khukri Rum begins with the finest ingredients, nurtured by the pure mountain air and pristine waters. Through a blend of ancient techniques and modern innovations, our craftsmen weave magic into every bottle.</p>
                                <Link to="#">Read More One more here</Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rumBottle d-flex">
                                <div class="rumBottleSingle blackRum">
                                    <img src={blackRum} alt="Kali RUM" className="w-100"/>
                                </div>
                                <div class="rumBottleSingle redRum">
                                    <img src={redRum} alt="Kali RUM" className="w-100"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;