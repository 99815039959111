import React from "react";
import { Link, useLocation } from "react-router-dom";
import kLogo from "../../assets/images/logo-k.png";

const Header = () => {
    const location = useLocation();
    return(
        <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand text-white" to="/">
                <img src={kLogo} className="w-50 img-responsive" alt="Khukri RUM Logo" />
            </Link>
            <button className="navbar-toggler">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse justify-content-end d-flex">
                <ul className="navbar-nav ml-auto">
                    <li className={`nav-item ${location.pathname === '/' ? "active" : ""}`}>
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/about' ? "active" : ""}`}>
                        <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">Our Blends</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">Our Craft</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">Contact Us</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
export default Header;